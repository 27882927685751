import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {CashierExpressService} from '../services/CashierExpressService';
import {WithResultObservation} from '../../hooks/useFunctionResultObservation.worker';
import {
  OnPaymentAuthorizedResult,
  OnShippingContactSelected,
  PaymentAuthorizedArgs,
} from '@wix/cashier-express-checkout-widget/src/types/ExternalContract';
import {CartService} from '../services/CartService';
import {cashierExpressAddressToEcomAddress} from '@wix/wixstores-client-storefront-sdk/dist/es/src/cart/cashierExpressAddressToEcomAddress/cashierExpressAddressToEcomAddress';
import {
  CashierCheckoutDataApi,
  GetCashierCheckoutDataQuery,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CashierCheckoutDataApi/CashierCheckoutDataApi';
import {ShippingError} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import {BreakdownTypes, PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {CheckoutNavigationService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/checkout-services/CheckoutNavigationService/CheckoutNavigationService';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {OriginTypes} from '../../components/cart/constants';
import {ButtonStyle, Shape, Theme} from '@wix/cashier-express-checkout-widget/dist/src/types/Styles';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {BIService} from '../services/BIService';
import {OrderService} from '../services/OrderService';
import {ButtonSkins} from '../../types/app.types';
import {NavigationService} from '../services/NavigationService';
import {ModalManagerService} from '../services/ModalManagerService';

export class CashierExpressStore {
  private readonly cartService: CartService;
  private readonly navigationService: NavigationService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly biService: BIService;
  private readonly orderService: OrderService;
  private readonly modalManagerService: ModalManagerService;
  private countryCodes: GetCashierCheckoutDataQuery['localeData']['countries'];
  private isTermsAndConditionsEnabled: boolean;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly cashierExpressService: CashierExpressService,
    {
      cartService,
      checkoutNavigationService,
      storeMetaDataService,
      biService,
      orderService,
      navigationService,
      modalManagerService,
    }: {
      cartService: CartService;
      checkoutNavigationService: CheckoutNavigationService;
      storeMetaDataService: StoreMetaDataService;
      biService: BIService;
      orderService: OrderService;
      navigationService: NavigationService;
      modalManagerService: ModalManagerService;
    },
    private readonly styleSettingsService: StyleSettingsService,
    private readonly withResultObservation: WithResultObservation
  ) {
    this.cartService = cartService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.navigationService = navigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.orderService = orderService;
    this.biService = biService;
    this.modalManagerService = modalManagerService;
  }

  private get cashierExpressEnabled() {
    return this.siteStore.experiments.enabled('specs.stores.ResponsiveCartAndPayPalButtons');
  }

  private readonly fetchCashierCheckoutDataIfNeeded = async () => {
    if (this.countryCodes) {
      return;
    }
    const response = await new CashierCheckoutDataApi({siteStore: this.siteStore, origin: ''}).getCashierCheckoutData();
    this.countryCodes = response.countries;
    this.isTermsAndConditionsEnabled = response.termsAndConditions?.enabled;
  };

  private readonly handlePaymentMethodError = () => {
    if (!this.siteStore.experiments.enabled('specs.stores.HandlePaymentMethodErrorInCartOOI')) {
      return;
    }

    const pmName = this.siteStore.queryParamsService.getQueryParam('pmName');
    const pmUrl = this.siteStore.queryParamsService.getQueryParam('pmUrl') ?? '';

    if (pmName !== undefined && pmName !== '') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.modalManagerService.modalManger.openErrorWithPaymentMethod({
        pmName: pmName.replace(/\//g, ''),
        pmUrl: pmUrl.replace(/\//g, ''),
      });
    }
  };

  private readonly onClick = async () => {
    this.navigationService.isNavigationToCheckoutInProcess = true;

    await this.fetchCashierCheckoutDataIfNeeded();

    const paymentMethods = (await this.storeMetaDataService.get()).activePaymentMethods;
    this.biService.clickOnCheckoutWithEWalletSf(this.cartService.cart, this.cartService.cartType, paymentMethods);

    const {hasCreatedPaymentMethods, canStoreShip, isPremium} = await this.storeMetaDataService.get();
    const {canCheckout, modalType} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      areAllItemsDigital: this.cartService.isDigitalCart,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination: true,
    });

    if (!canCheckout) {
      await this.checkoutNavigationService.openModalByType(
        modalType,
        this.styleSettingsService.isEditorX,
        this.cartService.cart
      );
      this.navigationService.isNavigationToCheckoutInProcess = false;
      return Promise.resolve({canceled: true});
    }
    setTimeout(() => (this.navigationService.isNavigationToCheckoutInProcess = false), 5_000);
    return Promise.resolve({canceled: false});
  };

  private readonly onShippingContactSelected: OnShippingContactSelected = async (shippingContact) => {
    const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(shippingContact, {}, this.countryCodes);
    const cartId = this.cartService.cart.cartId;
    await this.cartService.setShippingAddressesForFastFlow({cartId, country, subdivision, zipCode});
    const cart = await this.cartService.fetchCartWithTaxAndShippingIncluded();
    const notEnoughInfoAboutSubdivision = cart.destinationCompleteness.includes('SUBDIVISION');

    const totals = cart.totals;

    const initialPaymentBreakdown: PaymentBreakdown = {
      [BreakdownTypes.Shipping]: totals.shipping.toString(),
      //todo(EE-30329): we can't test this right now, waiting for PAY-7283
      [BreakdownTypes.Tax]: this.orderService.taxOnProduct ? '0' : totals.tax.toString(),
      [BreakdownTypes.Discount]: totals.discount.toString(),
      [BreakdownTypes.ItemsTotal]: totals.itemsTotal.toString(),
    };

    const hasShippingRules = this.orderService.hasShippingRules;

    if (cart.shippingRuleInfo.canShipToDestination || notEnoughInfoAboutSubdivision || hasShippingRules) {
      return {
        paymentAmount: cart.totals.total.toString(),
        paymentBreakdown: initialPaymentBreakdown,
      };
    } else {
      return {error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE};
    }
  };

  private readonly onPaymentAuthorized = async (
    paymentInfo: PaymentAuthorizedArgs,
    accessibilityEnabled: boolean
  ): Promise<OnPaymentAuthorizedResult> => {
    /* istanbul ignore if - can't test with current cashier testKit */
    if (paymentInfo.error) {
      throw paymentInfo.error;
    }

    const paymentMethods = (await this.storeMetaDataService.get()).activePaymentMethods;

    this.biService.clickContinueEWalletModalSf(this.cartService.cart, this.cartService.cartType, paymentMethods);
    this.cartService.trackInitiateCheckout();

    const shouldRequestShipping = this.shouldRequestShipping();
    if (shouldRequestShipping) {
      await this.cartService.setCartAddress({
        cartId: this.cartService.cart.cartId,
        address: cashierExpressAddressToEcomAddress(
          paymentInfo.shippingContact,
          paymentInfo.billingContact,
          this.countryCodes
        ),
      });
    } else {
      await this.cartService.setCartBillingAddress({
        cartId: this.cartService.cart.cartId,
        billingAddress: cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.countryCodes),
      });
    }

    await this.cartService.fetchCart();
    const cart = this.cartService.cart;
    const notEnoughInfoAboutSubdivision = this.cartService.cart.destinationCompleteness.includes('SUBDIVISION');

    const canPayWithoutNavigatingToCheckout =
      !shouldRequestShipping && !this.isTermsAndConditionsEnabled && !notEnoughInfoAboutSubdivision;
    if (canPayWithoutNavigatingToCheckout) {
      const placeOrderResponse = await this.cartService.placeOrder({
        cartId: cart.cartId,
        paymentId: paymentInfo.detailsId,
        shouldRedirect: true,
        isPickupFlow: false,
        inUserDomain: true,
        forceLocale: this.siteStore.locale,
        deviceType: this.siteStore.isMobile() ? 'mobile' : 'desktop',
      });
      const wasPlaceOrderSuccessful = placeOrderResponse.cartStatus.success;
      if (!wasPlaceOrderSuccessful) {
        return {result: 'error'};
      }
      await this.siteStore.navigate(
        {
          sectionId: PageMap.THANKYOU,
          queryParams: {objectType: 'order'},
          state: placeOrderResponse.orderId,
        },
        true
      );

      return {result: 'success'};
    } else {
      const storeMetaData = await this.storeMetaDataService.get();
      await this.checkoutNavigationService.navigateToCheckout({
        cartId: cart.cartId,
        isFastFlow: false,
        siteBaseUrl: this.siteStore.location.baseUrl,
        a11y: accessibilityEnabled,
        cashierPaymentId: paymentInfo.detailsId,
        isPickupOnly: storeMetaData.isPickupOnly,
        deviceType: this.siteStore.isMobile() ? 'mobile' : 'desktop',
        locale: this.siteStore.locale,
        originType: OriginTypes.Paypal,
      });
      return {result: 'success'};
    }
  };

  private shouldRequestShipping(): boolean {
    const isDigitalCart = this.cartService.isDigitalCart;
    const isPickup = this.orderService.isPickup;
    return !(isDigitalCart || isPickup);
  }

  private getButtonStyle(): ButtonStyle {
    if (!this.styleSettingsService.cornerRadius) {
      return {shape: 'rect' as Shape, height: 42};
    }
    if (
      this.styleSettingsService.cornerRadius.value === '0px' ||
      this.styleSettingsService.cornerRadius.value === '5px'
    ) {
      return {shape: 'rect' as Shape, height: 42};
    }
    return {shape: 'pill' as Shape, height: 42};
  }

  private getButtonTheme(): Theme {
    if (!this.styleSettingsService.selectedSkin) {
      return 'dark';
    }
    if (
      this.styleSettingsService.selectedSkin.value === ButtonSkins.BUTTON_SKIN_1 ||
      this.styleSettingsService.selectedSkin.value === ButtonSkins.BUTTON_SKIN_2 ||
      this.styleSettingsService.selectedSkin.value === ButtonSkins.BUTTON_SKIN_3
    ) {
      return 'dark';
    }
    return 'light';
  }

  private getProps() {
    return {
      handlePaymentMethodError: this.handlePaymentMethodError,
      shouldShowDynamicPaymentOptions: true,
      ...this.withResultObservation({
        onClick: this.onClick,
        onShippingContactSelected: this.onShippingContactSelected,
        onPaymentAuthorized: this.onPaymentAuthorized,
      }),
      dynamicPaymentOptionsProps: {
        currency: this.siteStore.currency,
        meta: {
          appDefId: APP_DEFINITION_ID,
          appInstanceId: this.siteStore.storeId,
          siteId: this.siteStore.msid,
          visitorId: this.siteStore.uuid as string,
        },
        demoMode: this.siteStore.isEditorMode(),
        locale: this.siteStore.locale,
        domain: this.siteStore.location.baseUrl,
        orderItems: this.cashierExpressService.getOrderItems(),
        buttonStyle: this.getButtonStyle(),
        getButtonTheme: this.getButtonTheme(),
        shouldRequestShipping: this.shouldRequestShipping(),
      },
    };
  }

  public toProps(): Partial<ReturnType<CashierExpressStore['getProps']>> {
    if (this.cashierExpressEnabled) {
      return this.getProps();
    }

    return {
      shouldShowDynamicPaymentOptions: false,
      handlePaymentMethodError: this.handlePaymentMethodError,
    };
  }
}
